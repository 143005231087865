<template>
  <div v-if="isLoading">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      color="warning"
      timeout="-1"
      outlined
      top
      right
      transition="slide-x-reverse-transition"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <v-card-text>
      <v-card class="mx-auto mb-12">
        <section-header :isCollapsable="false">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4"
                ><span>{{ this.survey.title }} </span>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="float-right">
                  <template v-if="showDeleteButton">
                    <v-btn
                      color="error"
                      class="mr-2"
                      @click="remove"
                      outlined
                      :loading="isEditing"
                    >
                      Delete Survey
                      <v-icon aria-label="Delete  Survey"> mdi-delete </v-icon>
                    </v-btn>
                  </template>

                  <template>
                    <v-btn
                      v-if="showEditButton"
                      :to="{
                        name: 'SurveysNewEdit',
                        params: { id: survey.id },
                      }"
                      color="primary"
                      class="mr-2"
                      :disabled="isEditing"
                      :loading="isEditing"
                    >
                      Edit Survey
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Sponsor:</dt>
                <dd v-text="survey.sponsorName"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Status:</dt>
                <dd v-text="status"></dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ConfirmDialog from "@utils/components/ConfirmDialog";
import SectionHeader from "@admin/components/SectionHeader.vue";

export default {
  name: "SurveysNewView",
  components: {
    SectionHeader,
    ConfirmDialog,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isEditing: false,
      snackbar: false,
      snackbarMessage: "",
    };
  },
  computed: {
    status() {
      return this.survey.isActive ? "Active" : "Inactive";
    },
    ...mapState("screeners", ["screener"]),
    ...mapState("surveysNew", ["survey"]),
    ...mapGetters("surveysNew", ["surveyPage"]),
    ...mapGetters(["acceptRoles"]),
    showDeleteButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    showEditButton() {
      return this.acceptRoles(["Admin", "Manager", "Editor", "Super User"]);
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("surveysNew", {
      deleteSurvey: "deleteSurvey",
      getSurvey: "getSurvey",
      refreshSurvey: "clearSurvey",
    }),
    // delete survey
    remove() {
      if (this.isEditing) return;

      this.$refs.confirm
        .open("Confirm", "Are you sure you want to delete this survey?")
        .then((confirm) => {
          if (confirm) {
            this.isEditing = true;
            const screenerId = this.survey.screenerId;
            this.deleteSurvey(this.survey.id).then(([success]) => {
              this.isEditing = false;
              if (success) {
                this.$router.push({
                  name: "ScreenerView",
                  params: { screenerId: screenerId },
                });
              } else {
                this.snackbar = true;
              }
            });
          }
        });
    },
  },
  async beforeMount() {
    this.isLoading = true;
    this.isPreviewLoading = true;
    const surveyPromises = [];

    surveyPromises.push(this.getSurvey(this.id));

    await Promise.all(surveyPromises).then((responses) => {
      let success = true;
      for (const [responseSuccess] of responses) {
        if (!responseSuccess) {
          success = false;
          break;
        }
      }

      if (!success) {
        this.$router.push({ name: "NotFound" });
      } else {
        this.isLoading = false;
        this.isPreviewLoading = false;
      }

      this.setTitleObject(this.survey.title);
      this.setBreadcrumbs();
    });
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.refreshSurvey();
  },
};
</script>

<style>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
.v-input--selection-controls {
  margin: 0;
}
</style>
